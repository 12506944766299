<template>
  <b-card no-body>
    <b-card-body
        class="bv-example-row bv-example-row-flex-cols"
        :class="invoice.cancel_appeal || invoice.request_status?'bg-info':''"
    >
      <b-row>
        <b-col class="bv-example-row" md="1">
          {{ invoice.id }}
        </b-col>

        <b-col class="bv-example-row" md="1">
          {{ invoice.company.name }} <br>
          {{ invoice.company.tax_number }}
        </b-col>

        <b-col class="bv-example-row" md="2">
          {{ invoice.number }} <br>
          {{ invoice.order_date }}<br>
          {{ invoice.uuid }}
        </b-col>

        <b-col class="bv-example-row" md="2">
          {{ invoice.customer_name }} <br>
          {{ invoice.tax_number }}<br>
          <span v-if="invoice.order">{{ invoice.order.order_number }}</span>

        </b-col>

        <b-col class="bv-example-row" md="1">
          <img height="50" width="50" :src="'/img/py_logo/'+invoice.store.marketplace.image"/>
        </b-col>

        <b-col class="bv-example-row" md="1">
          {{ invoice.total }}
        </b-col>

        <b-col class="bv-example-row" md="1">
          {{ invoice.base }}
        </b-col>

        <b-col class="bv-example-row" md="1">
          {{ invoice.vat }}
        </b-col>

        <b-col class="bv-example-row" md="2">
          <b-badge variant="info">{{ invoice.status }}</b-badge>
          <b-badge
              :variant="invoiceStatus"
              title="Fatura Göster"
              @click="showInvoice"
          >Göster
          </b-badge>
          <br>
          <b-badge variant="danger" v-if="invoice.cancel_appeal">İptal Talebi</b-badge>
          <b-badge variant="danger" v-if="invoice.request_status">İptal Kabulü</b-badge>
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
export default {
  name: 'Invoice',
  props: {
    invoice: {
      type: Object,
      required: true,
    },
  },
  computed: {
    invoiceUrl() {
      return process.env.VUE_APP_IMG_URL + 'storage/' + this.invoice.url
    },
    invoiceStatus() {
      if (this.invoice) {
        if (this.invoice.request_status) return 'danger'
        if (this.invoice.cancel_appeal) return 'warning'
        if (this.invoice.status == 'Onaylandı') return 'success'
        return 'info'
      }
      return ''
    },
  },
  methods: {
    showInvoice() {
      this.$store.dispatch('order/getBasicInvoicePDF', this.invoice.uuid)
          .then(res => {
            if (res.status == 'success') {
              this.$swal({
                width: '780px',
                html: '<embed src="data:application/pdf;base64,' + res.content + '" type="application/pdf" width="700" height="700" />',
              })
            } else {
              alert('Hata')
            }
          })
    },
  },
}
</script>

<style scoped>

</style>
