<template>
  <div>
    <Filters/>
    <Invoice v-for="invoice in _invoices" :key="invoice.id" :invoice="invoice"/>
  </div>
</template>

<script>
import Filters from '@/views/invoice/invoice/Filters.vue'
import Invoice from '@/views/invoice/invoice/Invoice.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'Invoices',
  components: {
    Invoice,
    Filters,
  },
  computed: {
    ...mapGetters('invoice', ['_invoices']),
  },
}
</script>

<style scoped>

</style>
