<template>
  <b-card no-body>
    <b-card-body>
      <b-row>
        <b-button variant="primary" @click="getInvoices">Getir</b-button>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
export default {
  name: 'Filters',
  methods: {
    getInvoices() {
      this.$store.dispatch('invoice/getInvoices')
        .then(res => {
          this.$swal({
            icon: res.status,
            title: res.message,
          })
        })
    },
  },
}
</script>

<style scoped>

</style>
